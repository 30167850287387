import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import useStyles from "./styles";
import { Form } from "@unform/web";
import Input from "../../../../components/Input";
import { Add, Remove } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { toast } from "react-toastify";
import history from "../../../../services/history";
import api from "../../../../services/api";
import { getHours, addMinutes } from "date-fns";

const sortTaskItem = (a, b) => a.order - b.order;

function Create({ location }) {
  let { edit } = location.state || {};
  const [data, setData] = React.useState(
    location.state ? (location.state.data ? location.state.data : null) : null
  );

  const initalTaskItens = [...(data?.taskItens ?? [])].sort(sortTaskItem);
  const [hour_open, setHour_open] = React.useState(
    data ? data.hour_open : getHours(new Date())
  );
  const formRef = React.useRef();
  const classes = useStyles();
  const [taskNumb, setTaskNumb] = React.useState(
    edit ? [...initalTaskItens].length : 1
  );
  const [taskCamps, setTaskCamps] = React.useState(
    edit
      ? [...initalTaskItens].map((item) => ({
          camp: item.camp,
          choices: item.choices,
          correct: item.correct,
        }))
      : [{ camp: false, choices: [] }]
  );
  const [change, setChange] = React.useState(true);
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [type, setType] = React.useState(data ? data.type : "Lobinho");
  const [loading, setLoading] = React.useState(false);
  const [isMultipleChoice, setIsMultipleChoice] = React.useState(
    location.state ? location.state?.data?.isMultipleChoice : false
  );

  function handleCLick(e, index) {
    setTaskCamps((prev) => {
      let vector = prev;

      vector[index] = { ...vector[index], camp: e?.target?.checked ?? false };

      return vector;
    });

    setChange(!change);
  }

  async function submit(e) {
    let query;

    if (edit) {
      query = {
        ...e,
        taskItens: e.taskItens.map((item, index) => ({
          ...item,
          camp: taskCamps[index].camp,
          choices: item.choices,
          correct: taskCamps[index].correct,
          _id: [...initalTaskItens][index]
            ? [...initalTaskItens][index]._id
            : "new",
          order: index,
        })),
        date_open: selectedDate,
        hour_open,
        type,
        isMultipleChoice,
      };
    } else {
      query = {
        ...e,
        taskItens: e.taskItens.map((item, index) => ({
          ...item,
          camp: taskCamps[index].camp,
          choices: item.choices,
          correct: taskCamps[index].correct,
          order: index,
        })),
        date_open: selectedDate,
        hour_open,
        type,
        isMultipleChoice,
      };
    }

    try {
      setLoading(true);

      if (edit) {
        await api.put(`/tasks/${data._id}`, query);

        toast.success("Tarefa editada com sucesso.");
      } else {
        await api.post("/tasks", query);

        toast.success("Tarefa criada com sucesso.");
      }

      setLoading(false);
      history.push("/task");
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      setLoading(false);
    }
  }

  function addChoice(index) {
    setTaskCamps((prev) =>
      prev.map((task, taskIndex) =>
        taskIndex === index
          ? {
              ...task,
              choices: [...task.choices, ""],
            }
          : task
      )
    );
    setChange(!change);
  }

  function removeChoice(index) {
    setTaskCamps((prev) =>
      prev.map((task, taskIndex) =>
        taskIndex === index && task.choices?.length > 1
          ? { ...task, choices: task.choices.slice(0, task.choices.length - 1) }
          : task
      )
    );
    setChange(!change);
  }

  function addCamp() {
    setTaskNumb(taskNumb + 1);

    setTaskCamps((prev) => [
      ...prev,
      {
        camp: false,
        correct: 0,
        choices: [""],
      },
    ]);
    setChange(!change);
  }

  function removeCamp() {
    if (taskNumb > 1) {
      let newVector = taskCamps;
      newVector.pop();

      setTaskNumb(taskNumb - 1);
      setTaskCamps(newVector);
      setChange(!change);
    }
  }

  async function removeItem({ item }) {
    try {
      setLoading(true);

      const response = await api.delete(`/tasks/${data._id}/taskitem`, {
        params: {
          idItem: item._id,
        },
      });

      setData(response.data);
      setTaskNumb([...response.data.taskItens].length);
      setTaskCamps(
        [...response.data.taskItens].map((item) => ({
          camp: item.camp,
          choices: [],
        }))
      );

      setLoading(false);
      toast.success("Item removido com sucesso");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      }
      setLoading(false);
    }
  }

  function changeCorrect(index, value) {
    setTaskCamps((prev) =>
      prev.map((task, taskIndex) =>
        taskIndex === index ? { ...task, correct: Number(value) } : task
      )
    );
    setChange(!change);
  }

  React.useEffect(() => {
    if (data && data.date_open) {
      handleDateChange(
        addMinutes(new Date(data.date_open), new Date().getTimezoneOffset())
      );
    }
  }, [data]);

  useEffect(() => {
    if (isMultipleChoice) {
      setTimeout(() => {
        setTaskCamps((prev) =>
          prev.map((t, index) => ({
            ...t,
            correct:
              data?.taskItens[index]?.correct !== undefined
                ? data?.taskItens[index]?.correct
                : t.correct || 0,
            choices:
              t.choices && t.choices.length
                ? t.choices
                : [
                    {
                      text: true,
                      correct: true,
                    },
                  ],
          }))
        );
      }, 1000);
    }
  }, [data, isMultipleChoice]);

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>Criar tarefa</Typography>
        </Grid>
        <Grid item xs={12}>
          <Form
            ref={formRef}
            onSubmit={submit}
            initialData={edit ? { ...data, taskItens: initalTaskItens } : {}}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input name="title" label="Nome da tarefa" />
              </Grid>
              <Grid item xs={6}>
                <Input name="number" label="Numero da tarefa" type="number" />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="description"
                  label="Descrição da tarefa"
                  rowsMax={20}
                  multiline
                />
              </Grid>
              <Grid item xs={6}>
                <Input name="mediaLink" label="Link do video" />
              </Grid>
              <Grid item xs={6}>
                <Input name="agentAlert" label="Mensagem pro corretor" />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="coordenatorAlert"
                  label="Messagem pro coordenador da equipe"
                />
              </Grid>

              <Grid item xs={6}>
                <Input name="author" label="Autor da tarefa" />
              </Grid>

              <Grid item xs={6}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={"Lobinho"}>Lobinho</MenuItem>
                  <MenuItem value={"Escoteiro"}>Escoteiro</MenuItem>
                  <MenuItem value={"Senior"}>Senior</MenuItem>
                  <MenuItem value={"Pioneiro"}>Pioneiro</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={6}>
                <KeyboardDatePicker
                  style={{ width: "100%" }}
                  label="Data"
                  inputVariant="outlined"
                  format="dd/MM/yyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ width: "100%" }}
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ width: "100%" }}
                  >
                    Hora
                  </InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    inputVariant="outlined"
                    value={hour_open}
                    onChange={(e) => setHour_open(e.target.value)}
                    label="Hora"
                  >
                    {[
                      0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7,
                      7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13,
                      13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5,
                      19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5,
                    ].map((item) => (
                      <MenuItem value={item}>
                        {(
                          (item < 10 ? "0" : "") +
                          (item % 1 === 0 ? item : item - 0.2).toFixed(2)
                        ).replace(".", ":")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  name="isMultipleChoice"
                  control={
                    <Checkbox
                      checked={isMultipleChoice}
                      onChange={(e) => setIsMultipleChoice(e.target.checked)}
                    />
                  }
                  label="Multipla escolha"
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography className={classes.info}>
                      Itens da tarefa
                    </Typography>
                  </Grid>

                  <Grid item>
                    <IconButton
                      style={{ marginRight: 10 }}
                      onClick={() => addCamp()}
                    >
                      <Add />
                    </IconButton>

                    <IconButton onClick={() => removeCamp()}>
                      <Remove />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              {[...Array(taskNumb)].map((item, index) => (
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  {data && (
                    <IconButton
                      onClick={() =>
                        removeItem({ item: initalTaskItens[index] })
                      }
                    >
                      <Remove />
                    </IconButton>
                  )}

                  <Input
                    name={`taskItens.${index}.grade`}
                    type="number"
                    label={`Item ${Number(index) + 1} - Nota maxima`}
                    style={{ marginBottom: 20 }}
                  />

                  {!isMultipleChoice && (
                    <Input
                      name={`taskItens.${index}.campName`}
                      style={{ marginBottom: 20 }}
                      label={`Item ${
                        Number(index) + 1
                      } - Nome do campo de resposta`}
                    />
                  )}

                  <Input
                    name={`taskItens.${index}.usersText`}
                    style={{ marginBottom: 20 }}
                    label={`Item ${Number(index) + 1} - texto para usuario`}
                  />
                  <Input
                    name={`taskItens.${index}.agentsText`}
                    style={{ marginBottom: 20 }}
                    label={`Item ${Number(index) + 1} - texto para corretor`}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={taskCamps[index]?.camp}
                        onChange={(e) => handleCLick(e, index)}
                        name="checkedA"
                      />
                    }
                    label={`User deve responder item ${Number(index) + 1} ?`}
                  />

                  {isMultipleChoice && taskCamps[index]?.camp ? (
                    <>
                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Typography>Respostas do item</Typography>
                          </Grid>

                          <Grid item>
                            <IconButton
                              style={{ marginRight: 10 }}
                              onClick={() => addChoice(index)}
                            >
                              <Add />
                            </IconButton>

                            <IconButton onClick={() => removeChoice(index)}>
                              <Remove />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>

                      <RadioGroup
                        defaultValue={0}
                        fullWidth
                        name={`taskItens.${index}.options`}
                        value={String(taskCamps[index].correct)}
                        onChange={(e) => changeCorrect(index, e.target.value)}
                      >
                        {taskCamps[index].choices?.map((choice, chIndex) => (
                          <FormControlLabel
                            fullWidth
                            value={String(chIndex)}
                            control={<Radio />}
                            label={
                              <Typography fullWidth>
                                <Input
                                  fullWidth
                                  name={`taskItens.${index}.choices.${chIndex}`}
                                  style={{ marginBottom: 20 }}
                                  label={`Item ${
                                    Number(chIndex) + 1
                                  } - texto para corretor`}
                                />
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </>
                  ) : null}
                </Grid>
              ))}

              <Grid item xs={12}>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <Button type="submit" variant="contained" color="primary">
                    {edit ? "Editar" : "Criar"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Create;
